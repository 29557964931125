var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-hidden bg-color1" },
    [
      _vm.screenFlash
        ? _c("div", {
            staticClass: "absolute top-0 bottom-0 left-0 right-0 bg-color2 z-50"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("info"),
      _vm._v(" "),
      _c("div", { staticClass: "video-aspect" }, [
        _c("video", {
          staticClass: "video-container",
          attrs: {
            id: "webcam",
            autoplay: "",
            playsinline: "",
            width: "1080",
            height: "1920"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "head-position-container" }, [
          _c("div", {
            staticClass: "bg-img head-position-image",
            style: {
              backgroundImage:
                "url('" + _vm.baseurl + "assets/bilder/outline.png" + "')"
            }
          })
        ]),
        _vm._v(" "),
        _c("canvas", {
          staticClass: "hidden canvas-image-container",
          attrs: { id: "canvas" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "webcam-button-container" }, [
        _c(
          "div",
          {
            staticClass: "webcam-button",
            on: {
              click: function($event) {
                return _vm.takePhoto()
              }
            }
          },
          [
            _c("i", { staticClass: "button-icon camera-icon fas fa-camera" }),
            _vm._v(" "),
            _c("div", { staticClass: "button-icon countdown-text hidden" })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "webcam-next-step-container hidden" }, [
        _c(
          "div",
          { staticClass: "webcam-button-container-wrapper" },
          [
            _c("div", { staticClass: "button-wrapper pr-5" }, [
              _c(
                "div",
                {
                  staticClass: "button-big",
                  on: {
                    click: function($event) {
                      return _vm.resetPhoto()
                    }
                  }
                },
                [
                  _c("div", {
                    staticClass: "button-text",
                    domProps: { textContent: _vm._s(_vm.resetBtn) }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "fas fa-trash-restore" })
                ]
              )
            ]),
            _vm._v(" "),
            _vm._t("camera", null, { setUserPhoto: _vm.setUserPhoto })
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }