











import Vue, { VNode } from 'vue';
import { VServices } from '@libTs/vue-base';
import { PhotoService } from '../services/photo.service';

const SERVICES = VServices({ photoS: PhotoService });

export default Vue.extend({
  name: 'image-select',
  props: {
    images: Array,
    buttonText: String,
  },

  data() {
    return {
      selectedImage: undefined,
    };
  },
  mounted: function () {},
  methods: {
    getSetCurrentImage: function () {
      const currentSlide = $('.slider-dest-images').slick('slickCurrentSlide');
      this.selectedImage = this.images[currentSlide].image;
      SERVICES.photoS.destImage = this.selectedImage;
    },
    triggerFaceSwap: function () {
      this.getSetCurrentImage();
      SERVICES.photoS.makeFaceSwap();
    },
  },
});
