var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("image"),
      _vm._v(" "),
      _c("div", { staticClass: "slider-button-container" }, [
        _c(
          "div",
          {
            staticClass: "button-big",
            attrs: { id: "button-make-faceswap" },
            on: {
              click: function($event) {
                return _vm.triggerFaceSwap()
              }
            }
          },
          [
            _c("div", { staticClass: "button-text" }, [
              _vm._v(_vm._s(_vm.buttonText))
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "far fa-arrow-right" })
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }