/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { createVueApp } from '@libTs/vue-base';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { BASEURL, SITEURL } from './cms.globals';

// Directive Imports
import { OverlayDirective } from './directives/v-overlay.directive';
import { TabsDirective } from './directives/v-tabs.directive';

// Component Imports
import camera from './components/camera.vue';
import imageSelect from './components/image-select.vue';
import QrcodeVue from 'qrcode.vue';

// Service Imports
import { OverlaysService } from './services/overlays.service';
import { PhotoService } from './services/photo.service';
import { TabsService } from './services/tabs.service';
import { FormService } from './services/form.service';

// Plugin Imports

// other Imports


// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app

createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#app',
    services: [
      TabsService,
      OverlaysService,
      PhotoService,
      FormService
    ],
    directives: [
      OverlayDirective,
      TabsDirective,
    ],
    components: {
      camera,
      imageSelect,
      QrcodeVue
    },
    modules: [],
  }
);

// Add Axios plugin for AJAX requests
Vue.use( VueAxios, axios );

