import { VBaseService } from '@libTs/vue-base';
import { SliderHelper } from '../helpers/slider.helper';

export class TabsService extends VBaseService {

  public inteval: NodeJS.Timeout | undefined = undefined;
  public gotToTab( tab: string ): void {

    const slider = new SliderHelper( '.slider' );

    const tabs = $( '.tab' );
    const goTo = Number( tab );


    if ( goTo == 0 ) {
      setTimeout( () => {
        clearInterval( this.inteval as NodeJS.Timeout );
      }, 100 );
    }

    if ( goTo == 1 ) {
      this.resetTabs();
    }

    if ( goTo == 2 ) {
      setTimeout( () => {
        slider.initSliders();
      } );
    }

    tabs.each( ( index, el ) => {
      $( el ).removeClass( 'tab-active' );
    } );

    if ( goTo !== undefined ) {
      const elToGoTo = tabs.eq( goTo );
      elToGoTo.addClass( 'tab-active' );
    }
  }

  public restart(): void {
    window.location.reload();
  }

  public resetTabs(): void {
    let secondsSinceLastActivity = 0;
    const maxInactivity = 60 * 2; //In sec.

    function timer() {
      secondsSinceLastActivity++;
      if ( secondsSinceLastActivity === maxInactivity ) {
        location.reload();
      }
    }

    this.inteval = setInterval( timer, 1000 );

    $( 'html' ).on( 'click', () => {
      clearInterval( this.inteval as NodeJS.Timeout );
      secondsSinceLastActivity = 0;
      this.inteval = setInterval( timer, 1000 );
    } );
  }
}