import { VBaseService } from '@libTs/vue-base';
import axios, { AxiosRequestConfig } from "axios";
import { BASEURL } from "../cms.globals";
import { VServices } from '@libTs/vue-base';
import { TabsService } from '../services/tabs.service';
import { OverlaysService } from '../services/overlays.service';

interface IBytesObj { length: number; data: Uint8Array; }

export class PhotoService extends VBaseService {
  public selfie: string = '';
  public destImage: string = '';
  public finalImageUrl: string = `${BASEURL}assets/bilder/result.jpg`;
  public actualImageUrl: string = '';
  public finalImageData: Blob;
  public countdown: boolean = false;
  public photoTaken: boolean = false;

  public makeFaceSwap(): void {
    const SERVICES = VServices( { tabs: TabsService, overlays: OverlaysService } );

    if ( !SERVICES.overlays.has( 'overlayLoading' ) ) {
      SERVICES.overlays.show( 'overlayLoading' );
    }

    const formData = new FormData();
    const selfieBlob = this.imageDataToBlob( this.selfie );
    const destImageBlob = this.imageDataToBlob( this.destImage );

    formData.append( 'src_img', selfieBlob, 'src.png' );
    formData.append( 'dst_img', destImageBlob, 'dst.png' );

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
    };

    axios.post( BASEURL + "actions/faceswap/swap/send-data",
      formData,
      config
    ).then( ( response ) => {
      const url = URL.createObjectURL( response.data );

      this.finalImageUrl = url;
      this.finalImageData = response.data;
      SERVICES.overlays.hide();
      SERVICES.tabs.gotToTab( '3' );
    } ).catch( ( error ) => {
      console.log( error );
      SERVICES.overlays.hide();
      alert( 'There was an error swapping your Face, please try again' );
    } );
  }

  public createImage(): void {
    const SERVICES = VServices( { tabs: TabsService, overlays: OverlaysService } );
    const formData = new FormData();
    const swappedImageBlob = this.finalImageData;

    if ( !SERVICES.overlays.has( 'overlayLoading' ) ) {
      SERVICES.overlays.show( 'overlayLoading' );
    }

    formData.append( 'out_img', swappedImageBlob, 'swap.png' );

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    };

    axios.post( BASEURL + "actions/faceswap/image/create-image",
      formData,
      config
    ).then( ( response ) => {
      console.log(response);
      const url = `${BASEURL}files/bilder/Faceswap/${response.data}`;

      SERVICES.overlays.hide();
      this.actualImageUrl = url;

      setTimeout( () => {
        location.reload();
      }, 120000 );

    } ).catch( ( error ) => {
      SERVICES.overlays.hide();
      console.log( error );
    } );
  }

  public imageDataToBytesArr( img: string ): IBytesObj {
    if ( typeof img === 'string' ) {
      const parts = img.split( ',' );
      if ( parts && parts.length > 1 ) {
        const bstr = atob( parts[ 1 ] );
        let n = bstr.length;
        const u8arr = new Uint8Array( n );
        while ( n-- ) {
          u8arr[ n ] = bstr.charCodeAt( n );
        }
        return {
          length: u8arr.length,
          data: u8arr
        };
      }
    }
    return {
      length: 0,
      data: null
    };
  }

  public imageDataToBlob( img: string ): Blob {
    const u8obj = this.imageDataToBytesArr( img );

    if ( u8obj.length > 0 ) {
      const parts = img.split( ',' );
      if ( parts && parts.length > 1 ) {
        const mime = parts[ 0 ].match( /:(.*?);/ )[ 1 ];
        return new Blob( [ u8obj.data ], { type: mime } );
      }
    }
    return null;
  }
}