import { VBaseService } from '@libTs/vue-base';
import { trim } from 'jquery';

export class FormService extends VBaseService {
  public errors: Array<string> = [];

  public emailInvalid = false;
  public email = '';
  public onFormSubmit( e: Event ): void {
    this.errors = [];
    this.emailInvalid = false;

    if ( trim( this.email ) === '' ) {
      this.emailInvalid = true;
      this.errors.push( "Your email can't be empty" );
    }

    if ( !validateEmail( trim( this.email ) ) && trim( this.email ) !== '' ) {
      this.emailInvalid = true;
      this.errors.push( "Your email is not in the correct format" );
    }

    function validateEmail( email: string ): boolean {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test( email );
    }

    if ( this.errors.length ) {
      e.preventDefault();
    }
  }
}