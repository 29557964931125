import { VDirective, VServices } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';
import { TabsService } from '../services/tabs.service';

export const TabsDirective: VDirective = [
  'tabs', // translates to attribute "v-tabs" in HTML
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      const SERVICES = VServices( { tabs: TabsService } );
      const goto = binding.arg;
      if ( goto ) {
        $( el ).on( 'click', () => {
          SERVICES.tabs.gotToTab( goto );
        } );
      }
    },
  }
];
