















































import Vue, { VNode } from 'vue';
import Webcam from 'webcam-easy';
import { VServices } from '@libTs/vue-base';
import { PhotoService } from '../services/photo.service';
import { BASEURL } from '../cms.globals';

const SERVICES = VServices({ photoS: PhotoService });

export default Vue.extend({
  name: 'camera',
  props: {
    resetBtn: String,
    nextStep: String,
  },

  data() {
    return {
      webcam: undefined,
      webcamPreview: undefined,
      photo: undefined,
      photoCanvas: undefined,
      buttonContainer: undefined,
      nextStepContainer: undefined,
      countdownTime: 5,
      countdownFlashTime: 4.75,
      baseurl: BASEURL,
      photoS: SERVICES.photoS,
      screenFlash: false,
    };
  },
  mounted: function () {
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    this.webcam = new Webcam(webcamElement, 'user', canvasElement);
    this.buttonContainer = $('.webcam-button-container');
    this.nextStepContainer = $('.webcam-next-step-container');
    this.photoCanvas = $('#canvas');
    this.webcamPreview = $('video');

    this.webcam
      .start()
      .then((result: any) => {
        console.log('webcam started');
      })
      .catch((err: any) => {
        console.log(err);
      });
  },
  methods: {
    takePhoto: function () {
      setTimeout(() => {
        this.screenFlash = true;
      }, 1000 * this.countdownFlashTime);
      setTimeout(() => {
        this.photo = this.webcam.snap();
        this.screenFlash = false;

        if (this.photo) {
          SERVICES.photoS.photoTaken = true;
          this.webcamPreview.addClass('hidden');
          this.buttonContainer.addClass('hidden');
          this.photoCanvas.removeClass('hidden');
          this.nextStepContainer.removeClass('hidden');
          this.nextStepContainer.addClass('grid');
        }
      }, 1000 * this.countdownTime);
      this.countdown();
    },

    countdown: function () {
      let timeAvailable = this.countdownTime;
      const cameraIcon = $('.camera-icon');
      const countdownText = $('.countdown-text');

      cameraIcon.addClass('hidden');
      countdownText.removeClass('hidden');
      countdownText.text(timeAvailable);
      SERVICES.photoS.countdown = true;

      let countdown = setInterval(() => {
        timeAvailable -= 1;
        if (timeAvailable <= 0) {
          cameraIcon.removeClass('hidden');
          countdownText.addClass('hidden');
          clearInterval(countdown);
          this.webcam.stop();
          SERVICES.photoS.countdown = false;
        }
        countdownText.text(timeAvailable);
      }, 1000);
    },

    resetPhoto: function () {
      this.photo = undefined;
      SERVICES.photoS.photoTaken = false;
      this.webcamPreview.removeClass('hidden');
      this.buttonContainer.removeClass('hidden');
      this.photoCanvas.addClass('hidden');
      this.nextStepContainer.addClass('hidden');
      this.nextStepContainer.removeClass('grid');
      this.webcam.start();
    },

    setUserPhoto: function () {
      SERVICES.photoS.selfie = this.photo;
    },
  },
});
